import { getJWT } from '../utils/jwt';

const API_URL = process.env.REACT_APP_API_URL;

export const getMappings = () =>
  fetch(`${API_URL}/mappings`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`
    }
  });

export const getMapping = (id) =>
  fetch(`${API_URL}/mappings/${id}`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`
    }
  });

export const createMapping = (body) =>
  fetch(`${API_URL}/mappings`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`
    },
    body: JSON.stringify(body)
  });

export const updateMapping = (id, body) =>
  fetch(`${API_URL}/mappings/${id}`, {
    method: 'PUT',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`
    },
    body: JSON.stringify(body)
  });

export const deleteMapping = (id) =>
  fetch(`${API_URL}/mappings/${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`
    }
  });
