import { getJWT } from '../utils/jwt';

const API_URL = process.env.REACT_APP_API_URL;

export const getRecords = () =>
  fetch(`${API_URL}/records`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`
    }
  });

export const deleteRecord = (id) =>
fetch(`${API_URL}/records/${id}`, {
  method: 'DELETE',
  mode: 'cors',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `bearer ${getJWT()}`
  }
});