import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';

import rootReducer from './reducers';

const store = createStore(rootReducer, compose(
    applyMiddleware(thunkMiddleware),
    (process.env.NODE_ENV !== 'production') && (typeof window.__REDUX_DEVTOOLS_EXTENSION__ !== 'undefined') ? window.__REDUX_DEVTOOLS_EXTENSION__() : f => f
));

export default store;