import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import enUS from 'antd/lib/locale-provider/en_US';

import store from './redux/store';
import AppRouter from './router';
import './index.scss';

ReactDOM.render(
  <Provider store={store}>
    <ConfigProvider locale={enUS}>
      <AppRouter />
    </ConfigProvider>
  </Provider>,
  document.getElementById('root')
);
