import React, { useState, useEffect } from 'react';
import { Icon, Table } from 'antd';
import { CSVLink } from 'react-csv';
import moment from 'moment';

import * as recordApi from '../../../../api/record';

import './style.scss';

const NFCRecordPage = (props) => {
  const [data, setData] = useState([]);
  const fetchRecords = () => {
    recordApi
      .getRecords()
      .then((res) => {
        if (res.status === 400) {
          throw new Error('Bad response from server');
        } else {
          return res.json();
        }
      })
      .then((json) => {
        setData(json);
      })
      .catch((err) => console.error(err));
  };
  const deleteRecord = (id) => {
    recordApi
      .deleteRecord(id)
      .then((res) => res.json())
      .then((json) => fetchRecords());
  };

  const columns = [
    {
      title: 'NFC ID',
      dataIndex: 'nfcId',
      key: 'nfcId',
      align: 'center',
      render: (value, record) => record.nfcName ? `${record.nfcName} - ${value}` : value
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: 'ID Card Number',
      dataIndex: 'idCardNumber',
      key: 'idCardNumber',
      align: 'center'
    },
    {
      title: 'Gender',
      dataIndex: 'gender',
      key: 'gender',
      align: 'center'
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (value, record) => moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      align: 'center',
      width: 50,
      render: (value, record) => <Icon className="remove-btn" type="close" onClick={() => deleteRecord(record.id)} />
    }
  ];
  useEffect(fetchRecords, []);

  return (
    <div className="NFCRecordPage">
      <Table rowKey="id" columns={columns} dataSource={data} />
      <CSVLink
        className="floating-btn"
        data={data}
        filename={`nfc-records-${moment().toISOString()}.csv`}
        target="_blank"
      >
        <Icon type="cloud-download" />
      </CSVLink>
    </div>
  );
};

export default NFCRecordPage;
