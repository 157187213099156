import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { Dropdown, Icon, Layout, Menu } from 'antd';

import './style.scss';

const { Header, Content, Sider } = Layout;
const MenuItem = Menu.Item;

const DashboardPage = (props) => {
  const { children, location } = props;
  const userName = useSelector((state) => state.user.username);
  const [collapsed, setCollapsed] = useState(false);

  const handleLogout = (e) => {
    const { history } = props;
    sessionStorage.removeItem('jwt');
    history.push('/login');
  };

  const menu = (
    <Menu>
      <MenuItem key="logout" onClick={handleLogout}>
        Logout
      </MenuItem>
    </Menu>
  );

  return (
    <Layout className="DashboardPage">
      <Sider collapsible collapsed={collapsed} onCollapse={() => setCollapsed(!collapsed)}>
        <div className="logo" />
        <Menu theme="dark" selectedKeys={[location.pathname]} mode="inline">
          <MenuItem key="/dashboard/nfc-record">
            <NavLink to="/dashboard/nfc-record">
              <Icon type="idcard" />
              <span>NFC Record</span>s
            </NavLink>
          </MenuItem>
          <MenuItem key="/dashboard/nfc-tag-mapping">
            <NavLink to="/dashboard/nfc-tag-mapping">
              <Icon type="environment" />
              <span>NFC Tag Mappings</span>
            </NavLink>
          </MenuItem>
        </Menu>
      </Sider>
      <Layout>
        <Header>
          <div className="logo"></div>
          <div className="profile-menu">
            <Dropdown overlay={menu} trigger={['click']}>
              <div>
                {userName} <Icon type="down" />
              </div>
            </Dropdown>
          </div>
        </Header>
        <Content>{children}</Content>
      </Layout>
    </Layout>
  );
};

export default DashboardPage;
