import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import { Button, Icon, Input } from 'antd';

import { getJWT, setJWT } from '../../utils/jwt';
import * as authApi from '../../api/auth';
import './style.scss';

const LoginPage = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState();
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const jwt = getJWT();

  const handleSubmit = (e) => {
    console.log(process.env)
    e.preventDefault();
    setErrorMessage('');
    setIsLoggingIn(true);

    authApi
      .login({ username, password })
      .then((res) => res.json())
      .then((json) => {
        if (json.error) {
          setErrorMessage(json.error.message);
        } else {
          setJWT(json.token);
        }
        setIsLoggingIn(false);
      })
      .catch((err) => {
        console.error(err);

        setIsLoggingIn(false);
        setErrorMessage(err.response ? err.response.body.Message : err.message);
      });
  };

  if (jwt) {
    return <Redirect to="/dashboard" />;
  } else {
    return (
      <div className="LoginPage">
        <div className="LoginPage-container">
          <div className="LoginPage-logo-container">
            {/* <img className="LoginPage-logo" src="" alt="Logo" /> */}
            <p>NFC Check-in System Dashboard</p>
          </div>
          <div className="LoginPage-form-container">
            <p className="error-message">{errorMessage}</p>
            <form className="LoginPage-form" onSubmit={handleSubmit}>
              <Input
                prefix={<Icon type="user" style={{ fontSize: 13 }} />}
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <Input
                prefix={<Icon type="lock" style={{ fontSize: 13 }} />}
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <Button type="primary" htmlType="submit" className="login-form-button" loading={isLoggingIn}>
                Log in
              </Button>
            </form>
          </div>
        </div>
      </div>
    );
  }
};

export default LoginPage;
