import React, { Component } from 'react';
import { Button, Form, Input, Spin } from 'antd';

import * as mappingApi from '../../../../../../api/mapping';
import AutoUppercaseInput from './components/AutoUpperCaseInput';

const { Item: FormItem } = Form;

export class NFCTagMappingForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false
    };
  }

  fetchMapping(advertisementId) {
    const { form } = this.props;

    return mappingApi
      .getMapping(advertisementId)
      .then((res) => res.json())
      .then((json) => {
        const { nfcId, name } = json;
        form.setFieldsValue({ nfcId, name });
      })
      .catch((err) => console.log(err));
  }

  initForm() {
    const { selectedMappingId } = this.props;
    this.setState({ isLoading: true });

    if (selectedMappingId !== undefined) {
      this.fetchMapping(selectedMappingId).then(() => this.setState({ isLoading: false }));
    } else {
      this.setState({ isLoading: false });
    }
  }

  handleSubmit(e) {
    const { form, selectedMappingId, afterSubmit } = this.props;

    e.preventDefault();
    form.validateFields((err, values) => {
      if (!err) {
        (selectedMappingId === undefined
          ? mappingApi.createMapping(values)
          : mappingApi.updateMapping(selectedMappingId, values)
        )
          .then((res) => res.json())
          .then((json) => afterSubmit())
          .catch((err) => {
            console.log(err);
            afterSubmit();
          });
      }
    });
  }

  componentDidMount() {
    this.initForm();
  }

  render() {
    const { form } = this.props;
    const { isLoading } = this.state;

    const { getFieldDecorator } = form;
    const formItemLayout = {
      labelCol: { span: 6 },
      wrapperCol: { span: 14 }
    };

    return (
      <Spin tip="Loading..." spinning={isLoading}>
        <Form className="AdvertisementForm" {...formItemLayout} onSubmit={(e) => this.handleSubmit(e)}>
          <FormItem label="NFC ID">
            {getFieldDecorator('nfcId', {
              rules: [
                {
                  required: true,
                  message: 'NFC ID is required.'
                }
              ]
            })(<AutoUppercaseInput />)}
          </FormItem>
          <FormItem label="Name">
            {getFieldDecorator('name', {
              rules: [
                {
                  required: true,
                  message: 'Name is required.'
                }
              ]
            })(<Input />)}
          </FormItem>
          <FormItem wrapperCol={{ span: 12, offset: 6 }}>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </FormItem>
        </Form>
      </Spin>
    );
  }
}

export default Form.create()(NFCTagMappingForm);
