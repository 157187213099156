import React, { useState, useEffect } from 'react';
import { Drawer, Icon, Table } from 'antd';
import moment from 'moment';

import * as mappingApi from '../../../../api/mapping';
import NFCTagMappingForm from './components/NFCTagMappingForm';

import './style.scss';

const NFCTagMappingPage = (props) => {
  const [data, setData] = useState([]);
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [selectedMappingId, setSelectedMappingId] = useState();
  const fetchMappings = () => {
    mappingApi
      .getMappings()
      .then((res) => res.json())
      .then((json) => {
        setData(json);
      });
  };
  const deleteMapping = (id) => {
    mappingApi
      .deleteMapping(id)
      .then((res) => res.json())
      .then((json) => fetchMappings());
  };

  const columns = [
    {
      title: 'NFC ID',
      dataIndex: 'nfcId',
      key: 'nfcId',
      align: 'center'
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      align: 'center'
    },
    {
      title: 'Created at',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: (value, record) => moment(value).format('YYYY-MM-DD HH:mm:ss')
    },
    {
      title: '',
      dataIndex: 'edit',
      key: 'edit',
      align: 'center',
      width: 50,
      render: (value, record) => (
        <Icon
          className="edit-btn"
          type="edit"
          onClick={() => {
            setSelectedMappingId(record.id);
            setIsDrawerVisible(true);
          }}
        />
      )
    },
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      align: 'center',
      width: 50,
      render: (value, record) => <Icon className="remove-btn" type="close" onClick={() => deleteMapping(record.id)} />
    }
  ];

  useEffect(fetchMappings, []);

  return (
    <div className="NFCTagMappingPage">
      <Table rowKey="id" columns={columns} dataSource={data} />
      <div className="floating-btn" onClick={() => setIsDrawerVisible(true)}>
        <Icon type="plus" />
      </div>
      <Drawer
        title="Create a mapping"
        width={360}
        onClose={() => {
          setIsDrawerVisible(false);
          setSelectedMappingId(undefined);
        }}
        visible={isDrawerVisible}
        destroyOnClose={true}
      >
        <NFCTagMappingForm
          selectedMappingId={selectedMappingId}
          afterSubmit={() => {
            fetchMappings();
            setIsDrawerVisible(false);
            setSelectedMappingId(undefined);
          }}
        />
      </Drawer>
    </div>
  );
};

export default NFCTagMappingPage;
