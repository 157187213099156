import {
	UPDATE_USER_DATA,
	LOGOUT
} from '../actionTypes';

export const updateUserData = (json) => ({
    type: UPDATE_USER_DATA,
    payload: json
});

export const logout = () => ({
	type: LOGOUT
})