import React, { Component } from 'react';
import { Input } from 'antd';

export class AutoUpperCaseInput extends Component {
  onChange = (e) => {
    if (typeof e.target.value === 'string') {
      this.props.onChange(e.target.value.toUpperCase());
    }
  }

  render() {
    return <Input {...this.props} onChange={this.onChange} />;
  }
}

export default AutoUpperCaseInput;
