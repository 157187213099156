import React from 'react';
import { Icon, Spin } from 'antd';

import './style.scss';

const LoadingScreen = (props) => {
    const { loadingText } = props;
    const antIcon = <Icon type="loading" style={{ fontSize: 36 }} spin />;

    return (
        <div className="LoadingScreen">
        	<Spin size="large" tip={loadingText} indicator={antIcon}/>
        </div>
    );
}

export default LoadingScreen;