import { UPDATE_USER_DATA, LOGOUT } from '../actionTypes';

const INITIAL_STATE = {
  id: undefined,
  username: '',
  role: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_DATA:
      return {
        ...state,
        id: action.payload.id,
        username: action.payload.username,
        role: action.payload.role
      };
    case LOGOUT:
      return {
        ...state,
        id: undefined,
        name: '',
        role: ''
      };
    default:
      return state;
  }
};
