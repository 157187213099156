import { getJWT } from '../utils/jwt';

const AUTH_API_URL = process.env.REACT_APP_AUTH_API_URL;

export const login = ({ username, password }) =>
  fetch(`${AUTH_API_URL}/login`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`
    },
    body: JSON.stringify({
      username,
      password
    })
  });

export const authenticate = () =>
  fetch(`${AUTH_API_URL}/authenticate`, {
    method: 'POST',
    mode: 'cors',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `bearer ${getJWT()}`
    }
  });
