import React from 'react';
import { Router, Route, Redirect, Switch } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import PrivateRoute from './routes/PrivateRoute';

import LoginPage from './scenes/LoginPage';
import DashboardPage from './scenes/DashboardPage';
import NFCRecordPage from './scenes/DashboardPage/scenes/NFCRecordPage';
import NFCTagMappingPage from './scenes/DashboardPage/scenes/NFCTagMappingPage';

const history = createBrowserHistory();

const dashboardSection = ({ match, ...rest }) => (
  <DashboardPage match={match} {...rest}>
    <Switch>
      <Route exact path={`${match.url}/nfc-record`} component={NFCRecordPage} />
      <Route exact path={`${match.url}/nfc-tag-mapping`} component={NFCTagMappingPage} />
      <Redirect to={`${match.url}/nfc-record`} />
    </Switch>
  </DashboardPage>
);

const AppRouter = () => (
  <Router history={history}>
    <Switch>
      <Route
        exact
        path="/"
        render={() => {
          const jwt = localStorage.getItem('jwt');

          if (jwt) {
            return <Redirect to="/dashboard" />;
          } else {
            localStorage.removeItem('jwt');
            return <Redirect to="/login" />;
          }
        }}
      />
      <Route exact path="/login" component={LoginPage} />
      <PrivateRoute path="/dashboard" component={dashboardSection} />
      <Redirect to="/" />
    </Switch>
  </Router>
);

export default AppRouter;
